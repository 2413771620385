import { BehaviorSubject, map } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImagePreviewService {
  private imagePreviewSubject$ = new BehaviorSubject<any>(null);
  imagePreview$ = this.imagePreviewSubject$.asObservable().pipe(
    map((e: any) => {
      return e?.map((url: string, index: number) => {
        return {
          url: url,
          type: url?.split('.').pop()?.toLowerCase(),
          isActive: !index,
        };
      });
    })
  );

  previewImage(url: any | null) {
    this.imagePreviewSubject$.next(typeof url === 'string' ? [url] : url);
  }
}
