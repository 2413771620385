import { Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { PERMISSION } from './core/enums/permission';
import { environment } from 'src/environments/environment';
const title = getTitle();

function getTitle(): string {
  let title = 'RYD';
  if (environment.server === 'staging') {
    title += ':staging - ';
  } else if (environment.server === 'local') {
    title += ':local - ';
  } else {
    title += ' - ';
  }
  return title;
}

export const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./feature/auth/component/login/login.component').then(
            m => m.LoginComponent
          ),
        title: `${title}Login`,
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import(
            './feature/auth/component/forgot-password/forgot-password.component'
          ).then(m => m.ForgotPasswordComponent),
        title: `${title}Forgot Password`,
      },
      {
        path: 'profile',
        title: `${title}Profile`,
        canMatch: [AuthGuard],
        data: {
          permission: PERMISSION.common_permission,
        },
        loadComponent: () =>
          import(
            './feature/auth/component/profile-container/profile-container.component'
          ).then(m => m.ProfileContainerComponent),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./feature/auth/component/profile/profile.component').then(
                m => m.ProfileComponent
              ),
          },
          {
            path: 'reset-password',
            loadComponent: () =>
              import(
                './feature/auth/component/reset-password/reset-password.component'
              ).then(m => m.ResetPasswordComponent),
          },
        ],
      },
    ],
  },
  {
    path: 'dashboard',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/dashboard/dashboard.component').then(
        c => c.DashboardComponent
      ),
    title: `${title}Dashboard`,
    data: {
      permission: PERMISSION.view_dashboard,
    },
  },
  {
    path: 'ride-booking',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/ride-booking/components/ride-booking/ride-booking.component'
      ).then(c => c.RideBookingComponent),
    title: `${title}Dashboard`,
    data: {
      permission: PERMISSION.view_dashboard,
    },
  },
  {
    path: 'requests',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/requests/requests.component').then(
        c => c.RequestsComponent
      ),
    data: {
      permission: PERMISSION.view_requests,
    },
    children: [
      {
        path: '',
        redirectTo: 'driver',
        pathMatch: 'full',
      },
      {
        path: 'driver',
        loadComponent: () =>
          import(
            './feature/requests/components/driver-requests/driver-requests.component'
          ).then(c => c.DriverRequestsComponent),
        title: `${title}Driver Requests`,
      },
      {
        path: 'car',
        loadComponent: () =>
          import(
            './feature/requests/components/car-requests/car-requests.component'
          ).then(c => c.CarRequestsComponent),
        title: `${title}Car Requests`,
      },
      {
        path: 'unblock',
        loadComponent: () =>
          import(
            './feature/requests/components/unblock-requests/unblock-requests.component'
          ).then(c => c.UnblockRequestsComponent),
        title: `${title}Unblock Requests`,
      },
    ],
  },
  {
    path: 'bookings',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/bookings/components/bookings/bookings.component').then(
        m => m.BookingsComponent
      ),
    data: {
      permission: PERMISSION.view_bookings,
    },
    title: `${title}Bookings`,
  },
  {
    path: 'riders',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/riders/components/riders/riders.component').then(
        m => m.RidersComponent
      ),
    data: {
      permission: PERMISSION.view_riders,
    },
    title: `${title}Riders`,
  },
  {
    path: 'riders/view',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/riders/components/rider-view-more/rider-view-more.component'
      ).then(m => m.RiderViewMoreComponent),
    data: {
      permission: PERMISSION.view_riders,
    },
    children: [
      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full',
      },
      {
        path: 'details',
        loadComponent: () =>
          import(
            './feature/riders/components/rider-details/rider-details.component'
          ).then(m => m.RiderDetailsComponent),
      },
      {
        path: 'trips',
        loadComponent: () =>
          import(
            './feature/riders/components/rider-trips/rider-trips.component'
          ).then(m => m.RiderTripsComponent),
      },
    ],
  },
  {
    path: 'discounts',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/discounts/components/discounts/discounts.component'
      ).then(m => m.DiscountsComponent),
    data: {
      permission: PERMISSION.view_discount_coupon,
    },
    title: `${title}Discounts and Offers`,
  },
  {
    path: 'payments',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/payments/components/payments/payments.component').then(
        c => c.PaymentsComponent
      ),
    data: {
      permission: PERMISSION.view_payment,
    },
    title: `${title}Payments`,
  },
  {
    path: 'feedback',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/feedback/components/feedback/feedback.component').then(
        c => c.FeedbackComponent
      ),
    data: {
      permission: PERMISSION.view_feedback,
    },
    title: `${title}Feedback`,
  },
  {
    path: 'drivers',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/drivers/components/drivers/drivers.component').then(
        c => c.DriversComponent
      ),
    title: `${title}Drivers`,
    data: {
      permission: PERMISSION.view_drivers,
    },
  },
  {
    path: 'drivers/detail',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/drivers/components/driver-details/driver-details.component'
      ).then(c => c.DriverDetailsComponent),
    data: {
      permission: PERMISSION.view_drivers,
    },
  },
  {
    path: 'blockDriver',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/drivers/components/block-driver-list/block-driver-list.component'
      ).then(c => c.BlockDriverListComponent),
    data: {
      permission: PERMISSION.view_drivers,
    },
  },
  {
    path: 'drivers/view',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/drivers/components/driver-view-more/driver-view-more.component'
      ).then(c => c.DriverViewMoreComponent),
    data: {
      permission: PERMISSION.view_drivers,
    },
    children: [
      {
        path: 'cars',
        loadComponent: () =>
          import(
            './feature/drivers/components/driver-car-details/driver-car-details.component'
          ).then(c => c.DriverCarDetailsComponent),
      },
      {
        path: 'trips',
        loadComponent: () =>
          import(
            './feature/drivers/components/driver-trips/driver-trips.component'
          ).then(c => c.DriverTripsComponent),
      },
    ],
  },
  {
    path: 'notifications',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/notification/components/notification/notification.component'
      ).then(c => c.NotificationComponent),
    title: `${title}Notifications`,
    data: {
      permission: PERMISSION.view_notification,
    },
  },
  {
    path: 'pricing-model',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/pricing-model/components/pricing-model/pricing-model.component'
      ).then(c => c.PricingModelComponent),
    title: `${title}Pricing Model`,
    data: {
      permission: PERMISSION.view_pricing_model,
    },
  },
  {
    path: 'pricing-model/model/:modelId',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/pricing-model/components/car-type-form/car-type-form.component'
      ).then(c => c.CarTypeFormComponent),
    data: {
      permission: PERMISSION.view_pricing_model,
    },
  },
  {
    path: 'sub-admin',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/sub-admin/components/sub-admins/sub-admins.component'
      ).then(c => c.SubAdminsComponent),
    title: `${title}Sub Admin`,
  },
  {
    path: 'sub-admin/user',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/sub-admin/components/sub-admin-form/sub-admin-form.component'
      ).then(c => c.SubAdminFormComponent),
    title: `${title}Sub Admin`,
  },
  {
    path: 'sub-admin/user/:id',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/sub-admin/components/sub-admin-form/sub-admin-form.component'
      ).then(c => c.SubAdminFormComponent),
    title: `${title}Sub Admin`,
  },
  {
    path: 'dispatchers',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/dispatchers/dispatchers.component').then(
        c => c.DispatchersComponent
      ),
    title: `${title}Dispatchers`,
    data: {
      permission: PERMISSION.view_dispatchers,
    },
  },
  {
    path: 'reports',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/reports/reports.component').then(
        c => c.ReportsComponent
      ),
    title: `${title}Reports`,
    data: {
      permission: PERMISSION.view_report,
    },
    children: [
      {
        path: '',
        redirectTo: 'bookings',
        pathMatch: 'full',
      },
      {
        path: 'bookings',
        loadComponent: () =>
          import(
            './feature/reports/components/bookings-report/bookings-report.component'
          ).then(c => c.BookingsReportComponent),
      },
      {
        path: 'drivers',
        loadComponent: () =>
          import(
            './feature/reports/components/drivers-report/drivers-report.component'
          ).then(c => c.DriversReportComponent),
      },
      {
        path: 'riders',
        loadComponent: () =>
          import(
            './feature/reports/components/riders-report/riders-report.component'
          ).then(c => c.RidersReportComponent),
      },
      {
        path: 'feedbacks',
        loadComponent: () =>
          import(
            './feature/reports/components/feedback-report/feedback-report.component'
          ).then(c => c.FeedbackReportComponent),
      },
      {
        path: 'payments',
        loadComponent: () =>
          import(
            './feature/reports/components/payments-report/payments-report.component'
          ).then(c => c.PaymentsReportComponent),
      },
    ],
  },
  {
    path: 'settings',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/settings/settings.component').then(
        c => c.SettingsComponent
      ),
    title: `${title}Settings`,
    children: [
      {
        path: 'payments-gateway',
        loadComponent: () =>
          import(
            './feature/settings/components/payments-gateway-settings/payments-gateway-settings.component'
          ).then(c => c.PaymentsGatewaySettingsComponent),
      },
      {
        path: 'message-service',
        loadComponent: () =>
          import(
            './feature/settings/components/message-service/message-service.component'
          ).then(c => c.MessageServiceComponent),
      },
      {
        path: 'apis-credentials',
        loadComponent: () =>
          import(
            './feature/settings/components/apis-credentials/apis-credentials.component'
          ).then(c => c.APIsCredentialsComponent),
      },
      {
        path: 'stripe',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/stripe-key/stripe-key.component'
          ).then(c => c.StripeKeyComponent),
      },
      {
        path: 'email',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/email/email.component'
          ).then(c => c.EmailComponent),
      },
      {
        path: 'google',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/google-key/google-key.component'
          ).then(c => c.GoogleKeyComponent),
      },
      {
        path: 'google-route',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/google-route-key/google-route-key.component'
          ).then(c => c.GoogleRouteKeyComponent),
      },
      {
        path: 'msg91',
        data: { key: 'msg91' },
        loadComponent: () =>
          import(
            './feature/settings/components/keys/msg91/msg91.component'
          ).then(c => c.Msg91Component),
      },
      {
        path: 'msg91-ride-details',
        data: { key: 'msg91-ride-details' },
        loadComponent: () =>
          import(
            './feature/settings/components/keys/msg91/msg91.component'
          ).then(c => c.Msg91Component),
      },
      {
        path: 'fcm',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/fcm-key/fcm-key.component'
          ).then(c => c.FcmKeyComponent),
      },
      {
        path: 'cashfree-payment',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/cash-free-payment/cash-free-payment.component'
          ).then(c => c.CashFreePaymentComponent),
      },
      {
        path: 'cashfree-payout',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/cashfree-payout/cashfree-payout.component'
          ).then(c => c.CashfreePayoutComponent),
      },
      {
        path: 'razorpay',
        loadComponent: () =>
          import(
            './feature/settings/components/keys/razor-pay/razor-pay.component'
          ).then(c => c.RazorPayComponent),
      },
      {
        path: 'driver-payout',
        loadComponent: () =>
          import('./feature/settings/components/payout/payout.component').then(
            c => c.PayoutComponent
          ),
      },
      {
        path: 'scratch-card',
        loadComponent: () =>
          import(
            './feature/settings/components/scratch-card/scratch-card.component'
          ).then(c => c.ScratchCardComponent),
      },
      {
        path: 'SAP-CID',
        loadComponent: () =>
          import(
            './feature/settings/components/agency-code/agency-code.component'
          ).then(c => c.AgencyCodeComponent),
      },
      {
        path: '',
        redirectTo: 'SAP-CID',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'subscription',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/subscription/components/subscription-details/subscription-details.component'
      ).then(c => c.SubscriptionDetailsComponent),
    title: `${title}Subscription`,
    data: {
      permission: PERMISSION.view_subscription_plan,
    },
  },
  {
    path: 'settlements',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/settlements/components/settlement/settlement.component'
      ).then(c => c.SettlementComponent),
    title: `${title}Settlements`,
    data: {
      permission: PERMISSION.view_subscription_plan,
    },
    children: [
      {
        path: '',
        redirectTo: 'driver',
        pathMatch: 'full',
      },
      {
        path: 'driver',
        loadComponent: () =>
          import(
            './feature/settlements/components/driver-settlement/driver-settlement.component'
          ).then(c => c.DriverSettlementComponent),
      },
      {
        path: 'rider',
        loadComponent: () =>
          import(
            './feature/settlements/components/rider-settlement/rider-settlement.component'
          ).then(c => c.RiderSettlementComponent),
      },
    ],
  },
  {
    path: 'settlements/driver',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/settlements/components/settlement-history/settlement-history.component'
      ).then(m => m.SettlementHistoryComponent),
    data: {
      permission: PERMISSION.view_subscription_plan,
    },
    children: [
      {
        path: '',
        redirectTo: 'payout-history',
        pathMatch: 'full',
      },
      {
        path: 'payout-history',
        loadComponent: () =>
          import(
            './feature/settlements/components/payout-history/payout-history.component'
          ).then(c => c.PayoutHistoryComponent),
        title: `${title}Payout History`,
      },
      {
        path: 'withdrawal-history',
        loadComponent: () =>
          import(
            './feature/settlements/components/withdrawal-history/withdrawal-history.component'
          ).then(c => c.WithdrawalHistoryComponent),
        title: `${title}Withdrawal History`,
      },
    ],
  },
  {
    path: 'settlements/rider/withdrawal-history',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import(
        './feature/settlements/components/rider-withdrawal-history/rider-withdrawal-history.component'
      ).then(c => c.RiderWithdrawalHistoryComponent),
    title: `${title}Withdrawal History`,
  },
  {
    path: 'service-tax',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/servicce-tax/service-tax/service-tax.component').then(
        c => c.ServiceTaxComponent
      ),
    title: `${title}Service Tax`,
    data: {
      permission: PERMISSION.view_service_tax,
    },
  },
  {
    path: 'sos',
    canMatch: [AuthGuard],
    loadComponent: () =>
      import('./feature/sos/components/sos-list/sos-list.component').then(
        c => c.SosListComponent
      ),
    title: `${title}Sos`,
    data: {
      permission: PERMISSION.view_sos,
    },
  },
  {
    path: 'track/:id',
    loadComponent: () =>
      import('./shared/components/tracking/tracking.component').then(
        c => c.TrackingComponent
      ),
    title: `${title}Track`,
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];
