import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  cleanParams(params: { [key: string]: any }): any {
    const newParams: any = {};
    for (const i in params) {
      if (!Object.is(params[i], null) && params[i] !== '') {
        newParams[i] = params[i];
      }
    }
    return newParams;
  }

  checkParams(params: any): boolean {
    let flag = false;
    for (const i in params) {
      if (i !== 'limit' && i !== 'offset' && params[i]) {
        flag = true;
      }
    }
    return flag;
  }
}
