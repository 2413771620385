import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private toogleSidebarSubject$ = new BehaviorSubject<boolean>(false);
  toggleSidebar$ = this.toogleSidebarSubject$.asObservable();

  private baseURL: string = environment.baseURL;
  private http = inject(HttpClient);
  private translateService = inject(TranslateService);

  toggleChange(event: any) {
    this.toogleSidebarSubject$.next(event);
  }

  getPrimaryColor(): string {
    const color = getComputedStyle(document.documentElement).getPropertyValue(
      '--theme-primary'
    );
    return color;
  }

  getChartSecondaryColor(): string {
    const color = getComputedStyle(document.documentElement).getPropertyValue(
      '--theme-chart-secondary'
    );
    return color;
  }

  getLocationPoints(location: any): Observable<any> {
    return this.http.post<any>(`${this.baseURL}admins/directions/`, {
      ...location,
    });
  }

  isRTL(): boolean {
    return this.translateService.currentLang === 'ar'; // Add RTL languages here if needed
    // this.translateService.onLangChange.subscribe((event) => {
    //   this.isRTL = event.lang === 'ar'; // Update on language change
    // });
  }

  setLanguage(lang: string) {
    return this.http.patch<any>(`${this.baseURL}accounts/update-language/`, {
      languagePreference: lang,
    });
  }
}
