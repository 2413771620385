import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/feature/auth/services/auth.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private translateService: TranslateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let languageCode;
    this.translateService.onLangChange.subscribe(event => {
      languageCode = event.lang === 'ar' ? 'ar' : 'en';
    });
    const token = this.authService.getToken();
    languageCode = this.commonService.isRTL() ? 'ar' : 'en'; // Replace 'ar' with the desired language code
    if (token)
      request = request.clone({
        setHeaders: {
          ...(token && { Authorization: `Token ${token}` }),
          'Accept-Language': languageCode,
        },
      });
    return next.handle(request);
  }
}
