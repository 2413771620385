<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title title-text" id="modal-basic-title">
      {{ (title$ | async) || 'Modal' | translate }}
    </h4>
    <i
      aria-label="Close"
      class="btn-close"
      (click)="d('Cross click')"
      [ngClass]="{ rtl: isRTL }"></i>
  </div>
  <div class="modal-body">
    <ng-container [ngComponentOutlet]="content$ | async"></ng-container>
  </div>
</ng-template>
