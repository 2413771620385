<header class="header" id="header">
  <div class="header_row d-flex h-100">
    <div
      class="d-flex align-items-start justify-content-center flex-column ham-header">
      <i
        [ngClass]="{ hidden: toggle }"
        (click)="click()"
        class="menu"
        [innerHTML]="icons['menu'] | safeHtml"></i>
    </div>
    <div
      class="logo_wrapper p-3 d-flex align-items-start justify-content-center w-100 h-100 flex-column">
      <img [routerLink]="['dashboard']" src="assets/img/Logo1.png" alt="logo" />
      <!-- <i
        [routerLink]="['dashboard']"
        [innerHTML]="icons['ryd'] | safeHtml"></i> -->
    </div>
    <div
      class="detail_wrapper d-flex justify-content-end align-items-center h-100 gap-3">
      <div class="language-selector">
        <ng-select
          class="custom"
          [clearable]="false"
          [searchable]="false"
          [ngModel]="selectedLanguage"
          (change)="changeLanguage($event)"
          [placeholder]="'Language'"
          [searchable]="false">
          <ng-option *ngFor="let lng of languages" [value]="lng.value">{{
            lng.label
          }}</ng-option>
        </ng-select>
      </div>
      <!-- <div
        class="menu-outside d-flex justify-content-center align-items-center">
        <i [innerHTML]="icons['menuArrow'] | safeHtml" class="menu-icon"></i>
      </div> -->
      <div class="d-flex align-items-center gap-md-3 gap-1">
        <ng-container *ngIf="userDetails$ | async as User">
          <div class="profile-dropdown dropdown d-flex">
            <button
              class="dropdown-trigger d-inline-flex align-items-center gap-2"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <img
                [src]="User.profilePic || 'assets/icon/profile.svg'"
                alt="avatar"
                class="profile-img" />
              <span class="d-flex align-items-center">
                {{ User.name }}
                <i [innerHTML]="icons['arrowDown'] | safeHtml"></i>
              </span>
            </button>
            <ul
              class="dropdown-menu border-0"
              aria-labelledby="dropdownMenuButton1">
              <li>
                <a
                  class="dropdown-item d-inline-flex align-items-center position-relative"
                  [ngClass]="{ rtl: isRTL }"
                  routerLink="/auth/profile">
                  <i [innerHTML]="icons['profile'] | safeHtml"></i>
                  {{ 'header.profile' | translate }}
                </a>
              </li>
              <ng-container *ngIf="userDetails.isAdmin">
                <li>
                  <a
                    class="dropdown-item d-inline-flex align-items-center position-relative"
                    [ngClass]="{ rtl: isRTL }"
                    (click)="goToSubAdmin()">
                    <i [innerHTML]="icons['userOctagon'] | safeHtml"></i>
                    {{ 'header.subAdmin' | translate }}
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item d-inline-flex align-items-center position-relative"
                    [ngClass]="{ rtl: isRTL }"
                    routerLink="/settings">
                    <i [innerHTML]="icons['settings'] | safeHtml"></i>
                    {{ 'header.settings' | translate }}
                  </a>
                </li>
              </ng-container>
              <li>
                <a
                  class="dropdown-item d-inline-flex align-items-center position-relative"
                  [ngClass]="{ rtl: isRTL }"
                  (click)="logOut()">
                  <i [innerHTML]="icons['logout'] | safeHtml"></i>
                  {{ 'header.logout' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container
          *ngIf="permissions.includes(permisions.view_notification)">
          <a
            class="notification-icon position-relative"
            routerLink="notifications">
            <i [innerHTML]="icons['notification'] | safeHtml"></i>
            <ng-container *ngIf="notificationCount">
              <span class="position-absolute d-block text-center">{{
                notificationCount < 100 ? notificationCount : '99+'
              }}</span></ng-container
            >
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</header>
