import { Router } from '@angular/router';
import { AuthService } from 'src/app/feature/auth/services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RideBookingService } from 'src/app/feature/ride-booking/services/ride-booking.service';
import { isString } from '@ngx-translate/core';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private rideService: RideBookingService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.spinner.show();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (request.responseType === 'blob' && error.error instanceof Blob) {
          // Read the Blob as text
          const reader = new FileReader();
          reader.onloadend = () => {
            try {
              // Convert the response text to a JSON object
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse && jsonResponse.message) {
                // Show the detailed message from the JSON response in the alert
                this.alertService.error(jsonResponse.message);
              } else if (jsonResponse && jsonResponse.detail) {
                this.alertService.error(jsonResponse.detail);
              } else {
                // Show a generic error message if the JSON response doesn't contain the 'message' property
                this.alertService.error(
                  'An error occurred while processing the file.'
                );
              }
            } catch (parseError) {
              // Show a generic error message if there's an error while parsing the JSON
              this.alertService.error(
                'An error occurred while processing the file.'
              );
            }
          };
          reader.readAsText(error.error); // Read the Blob as text
        } else if (error.error instanceof ErrorEvent) {
          errorMessage = `\nClient Side Error: \nMessage:${error.error.message}`;
        } else {
          if (error.status === 401) {
            this.alertService.error('Login is expired! Please login again.');
            localStorage.clear();
            sessionStorage.clear();
            this.authService.setIsAuthenticated();
            this.router.navigate(['/auth']);
          } else if (error.status >= 500) {
            this.alertService.error(
              `It's not you, it's us. \nServer error - Please try after some time.`
            );
          } else if (error.status === 404) {
            this.alertService.error(
              error.status + ' - ' + (error.error.detail || 'Not Found!')
            );
          } else {
            error.error.error && this.alertService.error(error.error.error);
            error.error.message && this.alertService.error(error.error.message);
            if (error.error.detail) {
              error.error.detail && this.alertService.error(error.error.detail);
            }
          }
        }

        // errorMessage = `Server Error - Error Code: ${error.status}\nMessage: ${error.message}`;
        return throwError(error);
      }),
      finalize(() => {
        this.rideService.spinner$.subscribe((spinner: boolean) => {
          if (spinner) {
            // Show findVehicleLoader and hide normalSpinner
            this.spinner.hide();
            this.spinner.show('findVehicleLoader');
          } else {
            // Hide all spinners
            this.spinner.hide();
            this.spinner.hide('findVehicleLoader');
          }
        });
      })
    );
  }
}
