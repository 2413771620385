import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import {
  catchError,
  delay,
  filter,
  map,
  Observable,
  retryWhen,
  tap,
} from 'rxjs';
import { AuthService } from 'src/app/feature/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket$: WebSocketSubject<any>;
  private webSocket$: WebSocketSubject<any> | null = null;

  constructor(private authService: AuthService) {
    this.socket$ = webSocket(
      environment.socketKey +
        'notification/' +
        '?Authorization=Token ' +
        this.authService.getToken()
    );
  }

  public connect() {
    return this.socket$.subscribe();
  }

  public getSocket(): Observable<any> {
    return this.socket$.asObservable();
  }

  getTrackingSocket(rideId: any) {
    const myWebSocket: WebSocketSubject<any> = webSocket(
      environment.socketKey + `ride/location/${rideId}/`
    );
    return myWebSocket.pipe(
      map((e: any) => {
        return e;
      })
    );
  }

  /**
   * Connect to WebSocket server with Authorization token.
   * @param url WebSocket URL with Authorization token
   * @returns Observable for WebSocket messages
   */
  connectSocket(url: string): Observable<any> {
    if (!this.webSocket$ || this.webSocket$.closed) {
      this.webSocket$ = webSocket({
        url,
        deserializer: e => JSON.parse(e.data), // Parse incoming messages
        serializer: value => JSON.stringify(value), // Serialize outgoing messages
      });
    }
    return this.webSocket$.asObservable().pipe(
      retryWhen(errors =>
        errors.pipe(
          tap(err => console.error('WebSocket error:', err)),
          delay(2000) // Retry after 2 seconds
        )
      ),
      catchError(err => {
        console.error('WebSocket connection failed:', err);
        throw err;
      })
    );
  }

  /**
   * Send a message to the WebSocket server.
   * @param message The payload to send
   */
  send(message: any): void {
    if (this.webSocket$) {
      this.webSocket$.next(message);
    } else {
      console.error('WebSocket is not connected. Unable to send message.');
    }
  }

  /**
   * Close the WebSocket connection.
   */
  close(): void {
    if (this.webSocket$) {
      this.webSocket$.complete();
      console.log('WebSocket connection closed.');
    }
  }
}
