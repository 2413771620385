import { RouterModule } from '@angular/router';
import { CommonModule, NgClass } from '@angular/common';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { Component, inject } from '@angular/core';
import { icons } from '../../../../assets/icon/svg';
import { CommonService } from '../../services/common.service';
import { PERMISSION } from 'src/app/core/enums/permission';
import { PermissionService } from 'src/app/feature/auth/services/permission.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [SafeHtmlPipe, CommonModule, RouterModule, TranslateModule, NgClass],
})
export class SidebarComponent {
  private permissionService = inject(PermissionService);
  private commonService = inject(CommonService);
  private translateService = inject(TranslateService);

  isRTL: boolean = this.commonService.isRTL();

  icons = icons;
  permissions: string[] = [];
  menuList = [
    {
      menuName: 'sidebar.dashboard',
      iconName: 'dashboard',
      url: '/dashboard',
      permission: PERMISSION.view_dashboard,
    },
    {
      menuName: 'sidebar.rideAllocator',
      iconName: 'rideBooking',
      url: '/ride-booking',
      permission: PERMISSION.view_dashboard,
    },
    {
      menuName: 'sidebar.bookings',
      iconName: 'calendar',
      url: '/bookings',
      permission: PERMISSION.view_bookings,
    },
    {
      menuName: 'sidebar.riders',
      iconName: 'userGroup',
      url: '/riders',
      permission: PERMISSION.view_riders,
    },
    {
      menuName: 'sidebar.dispatchers',
      iconName: 'dispatcher',
      url: '/dispatchers',
      permission: PERMISSION.view_dispatchers,
    },
    {
      menuName: 'sidebar.drivers',
      iconName: 'capBoy',
      url: '/drivers',
      permission: PERMISSION.view_drivers,
    },
    {
      menuName: 'sidebar.requests',
      iconName: 'documentPrevious',
      url: '/requests',
      permission: PERMISSION.view_requests,
    },
    {
      menuName: 'sidebar.reports',
      iconName: 'report',
      url: '/reports',
      permission: PERMISSION.view_report,
    },
    {
      menuName: 'sidebar.discounts',
      iconName: 'discounts',
      url: '/discounts',
      permission: PERMISSION.view_discount_coupon,
    },
    {
      menuName: 'sidebar.pricingModel',
      iconName: 'pricing',
      url: '/pricing-model',
      permission: PERMISSION.view_pricing_model,
    },
    {
      menuName: 'sidebar.feedback',
      iconName: 'feedback',
      url: '/feedback',
      permission: PERMISSION.view_feedback,
    },
    {
      menuName: 'sidebar.payments',
      iconName: 'card',
      url: '/payments',
      permission: PERMISSION.view_payment,
    },
    {
      menuName: 'sidebar.subscription',
      iconName: 'subscription',
      url: '/subscription',
      permission: PERMISSION.view_subscription_plan,
    },
    {
      menuName: 'sidebar.settlements',
      iconName: 'settlement',
      url: '/settlements',
      permission: PERMISSION.view_subscription_plan,
    },
    {
      menuName: 'sidebar.serviceTax',
      iconName: 'serviceTax',
      url: '/service-tax',
      permission: PERMISSION.view_service_tax,
    },
    {
      menuName: 'sidebar.sos',
      iconName: 'sos',
      url: '/sos',
      permission: PERMISSION.view_sos,
    },
  ];
  toggle$ = this.commonService.toggleSidebar$;

  ngOnInit() {
    this.getUserPages();
    this.translateService.onLangChange.subscribe(event => {
      this.isRTL = event.lang === 'ar'; // Update on language change
    });
  }

  getUserPages() {
    this.permissions = this.permissionService.getPermissions();
    this.menuList = this.menuList.filter((module: any) =>
      this.permissions.includes(module.permission)
    );
  }
}
