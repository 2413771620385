import { CommonModule } from '@angular/common';
import { Component, Inject, inject, Renderer2 } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { AuthService } from 'src/app/feature/auth/services/auth.service';
import { icons } from 'src/assets/icon/svg';
import { CommonService } from '../../services/common.service';
import { SocketService } from '../../services/socket.service';
import { PermissionService } from 'src/app/feature/auth/services/permission.service';
import { PERMISSION } from 'src/app/core/enums/permission';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LocalstorageService } from '../../services/localstorage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    SafeHtmlPipe,
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    TranslateModule,
  ],
})
export class HeaderComponent {
  private permissionService = inject(PermissionService);

  private translateService = inject(TranslateService);

  isRTL: boolean = this.commonService.isRTL();

  permisions = PERMISSION;

  public selectedLanguage: any;
  public icons: any = icons;
  toggle: boolean = false;
  notificationCount: any;
  permissions!: string[];
  userDetails$ = this.authService.userDetails$;
  userDetails: any = this.authService.getUser();

  public languages: any[] = [
    {
      label: 'English',
      image: 'EN',
      value: 'en',
    },
    {
      label: 'Arabic',
      image: 'AR',
      value: 'ar',
    },
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorage: LocalstorageService,
    private commonService: CommonService,
    private socketService: SocketService,
    private translate: TranslateService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.socketService.connect();

    this.socketService
      .getSocket()
      .pipe(takeUntilDestroyed())
      .subscribe((notification: any) => {
        this.notificationCount = notification.new_unread_notification;
      });
  }

  ngOnInit() {
    this.permissions = this.permissionService.getPermissions();
    this.selectedLanguage = this.localStorage.getItem('language') || 'en';
    this.translate.use(this.selectedLanguage);
    this.translateService.onLangChange.subscribe(event => {
      this.isRTL = event.lang === 'ar'; // Update on language change
    });
  }

  async logOut() {
    this.authService.logOut().subscribe(async res => {
      if (res) {
        await localStorage.clear();
        await sessionStorage.clear();
        await this.authService.setIsAuthenticated();
        this.router.navigate(['/auth']);
        this.renderer.setAttribute(document.documentElement, 'dir', 'ltr');
        this.renderer.removeClass(document.body, 'rtl');
        document.documentElement.classList.remove('rtl');
        this.translate.use('en');
      }
    });
  }

  goToSubAdmin() {
    this.router.navigate(['sub-admin']);
  }

  changeLanguage(lng: any) {
    this.selectedLanguage = lng;
    this.commonService.setLanguage(lng).subscribe();
    this.localStorage.setItem('language', this.selectedLanguage);
    this.translate.use(lng);
    if (lng === 'ar') {
      this.renderer.setAttribute(document.documentElement, 'dir', 'rtl');
      this.renderer.addClass(document.body, 'rtl');
      this.document.documentElement.classList.add('rtl');
    } else {
      this.renderer.setAttribute(document.documentElement, 'dir', 'ltr');
      this.renderer.removeClass(document.body, 'rtl');
      this.document.documentElement.classList.remove('rtl');
    }
  }

  click() {
    this.toggle = !this.toggle;
    this.commonService.toggleChange(this.toggle);
  }
}
