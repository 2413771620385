<ng-container *ngIf="!isSubAdmin">
  <div class="auth-wrapper">
    <div class="auth-inner-wrapper base-card">
      <div class="row mx-0">
        <div class="col-md-6 px-0 order-2 order-md-1">
          <div class="left_wrapper">
            <div class="auth_form">
              <h4 class="form-title">{{ 'login.auth.title' | translate }}</h4>
              <p class="form-subtitle">
                {{ 'login.auth.subtitle' | translate }}
              </p>
              <form action="" [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="form-group">
                  <label class="form-label">{{
                    'login.auth.form.email.label' | translate
                  }}</label>
                  <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    appAutoFocus
                    pattern="^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$"
                    [placeholder]="
                      'login.auth.form.email.placeholder' | translate
                    " />
                  <small
                    class="text-danger"
                    *ngIf="
                    formControls['email'].touched &&
                    formControls['email'].errors?.['required']
                  "
                    >{{
                      'login.auth.form.email.errors.required' | translate
                    }}</small
                  ><small
                    class="text-danger"
                    *ngIf="
                  formControls['email'].errors?.['pattern']
                  "
                    >{{
                      'login.auth.form.email.errors.invalid' | translate
                    }}</small
                  >
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    'login.auth.form.password.label' | translate
                  }}</label>
                  <div class="password-field" [ngClass]="{ rtl: isRTL }">
                    <img
                      [src]="
                        passwordField
                          ? 'assets/icon/eye.svg'
                          : 'assets/icon/eye-slash.svg'
                      "
                      alt="eye icon"
                      class="eye"
                      (click)="passwordField = !passwordField" />
                    <input
                      type="password"
                      [type]="passwordField ? 'text' : 'password'"
                      class="form-control"
                      formControlName="password"
                      [placeholder]="
                        'login.auth.form.password.placeholder' | translate
                      " /><small
                      class="text-danger"
                      *ngIf="
                        formControls['password'].touched &&
                        formControls['password'].invalid
                      "
                      >{{
                        'login.auth.form.password.errors.required' | translate
                      }}</small
                    >
                  </div>
                  <div class="form-group">
                    <div
                      class="d-flex flex-wrap align-items-center justify-content-between mt-2">
                      <label class="remeber-me mb-3"
                        ><input
                          type="checkbox"
                          checked="true"
                          (change)="rememberMe = !rememberMe" />
                        {{ 'login.auth.form.rememberMe' | translate }}</label
                      >
                      <a class="mb-3" routerLink="forgot-password">{{
                        'login.auth.form.forgotPassword' | translate
                      }}</a>
                    </div>
                  </div>
                </div>
                <button
                  [disabled]="loginForm.invalid"
                  class="btn btn-primary w-100"
                  type="submit">
                  {{ 'login.auth.form.submit' | translate }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 px-0 order-1 order-md-2">
          <div class="right_wrapper">
            <div class="login-bg-wrapper">
              <img src="/assets/img/login-bg.png" class="w-100" alt="logo" />
              <img src="/assets/img/Logo1.png" class="login-logo" alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div></div
></ng-container>
<ng-container *ngIf="isSubAdmin">
  <div class="auth-password-wrapper">
    <div class="auth-password-inner-wrapper">
      <div class="auth_form">
        <div class="auth-logo text-center">
          <img src="/assets/img/Logo1.png" alt="logo" />
        </div>
        <h4 class="form-title text-center mb-4 pb-2">
          {{ 'login.subAdmin.changePasswordTitle' | translate }}
        </h4>
        <form [formGroup]="newPasswordForm" (ngSubmit)="updatePassword()">
          <div class="form-group">
            <label class="form-label">{{
              'login.subAdmin.form.oldPassword.label' | translate
            }}</label>
            <div class="password-field" [ngClass]="{ rtl: isRTL }">
              <img
                [src]="
                  oldPasswordField
                    ? 'assets/icon/eye.svg'
                    : 'assets/icon/eye-slash.svg'
                "
                alt="eye icon"
                class="eye"
                (click)="oldPasswordField = !oldPasswordField" />
              <input
                type="password"
                [type]="oldPasswordField ? 'text' : 'password'"
                class="form-control"
                formControlName="oldPassword"
                [placeholder]="
                  'login.subAdmin.form.oldPassword.placeholder' | translate
                " />
              <small
                class="text-danger"
                *ngIf="
                  passwordControls['oldPassword'].touched &&
                  passwordControls['oldPassword'].invalid && passwordControls['oldPassword'].errors?.['required']
                "
                >{{
                  'login.subAdmin.form.oldPassword.errors.required' | translate
                }}</small
              >
              <small
                class="text-danger"
                *ngIf="passwordControls['oldPassword'].errors?.['pattern']">
                {{
                  'login.subAdmin.form.oldPassword.errors.pattern' | translate
                }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">{{
              'login.subAdmin.form.newPassword.label' | translate
            }}</label>
            <div class="password-field" [ngClass]="{ rtl: isRTL }">
              <img
                [src]="
                  newPasswordField
                    ? 'assets/icon/eye.svg'
                    : 'assets/icon/eye-slash.svg'
                "
                alt="eye icon"
                class="eye"
                (click)="newPasswordField = !newPasswordField" />
              <input
                type="password"
                [type]="newPasswordField ? 'text' : 'password'"
                class="form-control"
                formControlName="newPassword"
                [placeholder]="
                  'login.subAdmin.form.newPassword.placeholder' | translate
                " />
              <small
                class="text-danger"
                *ngIf="
                  passwordControls['newPassword'].touched &&
                  passwordControls['newPassword'].invalid && passwordControls['newPassword'].errors?.['required']
                "
                >{{
                  'login.subAdmin.form.newPassword.errors.required' | translate
                }}</small
              >
              <small
                class="text-danger"
                *ngIf="passwordControls['newPassword'].errors?.['pattern']">
                {{
                  'login.subAdmin.form.newPassword.errors.pattern' | translate
                }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">{{
              'login.subAdmin.form.confirmPassword.label' | translate
            }}</label>
            <div class="password-field" [ngClass]="{ rtl: isRTL }">
              <img
                [src]="
                  confirmPasswordField
                    ? 'assets/icon/eye.svg'
                    : 'assets/icon/eye-slash.svg'
                "
                alt="eye icon"
                class="eye"
                (click)="confirmPasswordField = !confirmPasswordField" />
              <input
                type="password"
                [type]="confirmPasswordField ? 'text' : 'password'"
                class="form-control"
                formControlName="confirmPassword"
                [placeholder]="
                  'login.subAdmin.form.confirmPassword.placeholder' | translate
                " />
              <small
                class="text-danger"
                *ngIf="
                  passwordControls['newPassword'].value !==
                    passwordControls['confirmPassword'].value &&
                  passwordControls['confirmPassword'].dirty &&
                  passwordControls['confirmPassword'].valid
                ">
                {{
                  'login.subAdmin.form.confirmPassword.errors.mismatch'
                    | translate
                }}</small
              >
              <small
                class="text-danger"
                *ngIf="
                  passwordControls['confirmPassword'].touched &&
                  passwordControls['confirmPassword'].invalid && passwordControls['confirmPassword'].errors?.['required']
                "
                >{{
                  'login.subAdmin.form.confirmPassword.errors.required'
                    | translate
                }}</small
              >
            </div>
          </div>
          <div class="text-center">
            <button
              [disabled]="
                newPasswordForm.invalid ||
                (passwordControls['newPassword'].value !==
                  passwordControls['confirmPassword'].value &&
                  passwordControls['confirmPassword'].dirty)
              "
              type="submit"
              class="btn btn-primary mt-4">
              {{ 'profile.resetPassword.title' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div></ng-container
>
