import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ParamsService } from 'src/app/shared/services/params.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RideBookingService {
  private baseURL: string = environment.baseURL;

  public spinnerSubject = new BehaviorSubject<boolean>(false);
  public spinner$ = this.spinnerSubject.asObservable();

  private http = inject(HttpClient);
  private paramsService = inject(ParamsService);

  getDiscountCouponList(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}riders/discount_coupon_list/`);
  }

  getRideQuotation(locations: any, loader: boolean): Observable<any> {
    // Create FormData
    const formData = new FormData();

    // Append common fields
    formData.append(
      'is_time_and_distance_exists',
      String(locations.is_time_and_distance_exists)
    );
    formData.append('is_secure', String(locations.is_secure));

    // Handle first-time payload
    if (locations.pickup && locations.destination) {
      formData.append(
        'pickup_locations',
        JSON.stringify({
          latitude: locations.pickup.lat,
          longitude: locations.pickup.lng,
          state: locations.pickup.state,
        })
      );
      formData.append(
        'destination_locations',
        JSON.stringify({
          latitude: locations.destination.lat,
          longitude: locations.destination.lng,
          state: locations.destination.state,
        })
      );
    }

    // Handle additional fields for second-time payload
    if (locations.distance) {
      formData.append('distance', String(locations.distance));
      formData.append('pickup', locations.pickup);
      formData.append('destination', locations.destination);
      formData.append('stops', JSON.stringify(locations.stops || []));
      formData.append('pickup_state', locations.pickup_state || '');
      formData.append('destination_state', locations.destination_state || '');
      formData.append('toll', String(locations.toll || 0));
      formData.append(
        'service_percentage',
        String(locations.service_percentage || 0)
      );
      formData.append('duration', String(locations.duration || 0));
    } else {
      formData.append('stops', JSON.stringify([]));
    }

    // Send the FormData object
    return this.http.post<any>(
      `${this.baseURL}riders/ride/quotation/`,
      formData
    );
  }

  setHeader(value: any) {
    const header = new HttpHeaders().set('loader', value);
    return header;
  }

  createRider(data: any, loader: boolean): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}accounts/create-rider-by-admin/`,
      data
    );
  }

  bookRide(payload: any, loader: boolean) {
    return this.http.post<any>(`${this.baseURL}riders/admin/ride/`, payload);
  }

  getVehicleType(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}admins/vehicle-types/`);
  }

  findDriver(rideId: any): Observable<any> {
    return this.http.get<any>(`${this.baseURL}riders/find/driver/${rideId}/`);
  }

  getAllocatedDriverList(rideId: any): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}admins/ride-requests/${rideId}/drivers/`
    );
  }
}
